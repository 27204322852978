import React, { useEffect } from 'react';
import mondaySdk from 'monday-sdk-js';
import jwtDecode from 'jwt-decode';

const monday = mondaySdk();

function getMaestroUrl(sessionToken, context) {
  if (!sessionToken.data) {
    return '';
  }

  const { dat } = jwtDecode(sessionToken.data);
  const domain = `${dat.slug}.monday.com`;
  const queryParams = new URLSearchParams({
    account_id: dat.account_id.toString(),
    container_id: (context?.data?.boardId ? `monday||${domain}||${context.data.boardId}` : '').toString(),
    user_id: dat.user_id.toString(),
    xdm_e: domain,
  });
  const params = queryParams.toString() ? `?${queryParams.toString()}` : '';
  return `${process.env.REACT_APP_MAESTRO_BASE_URL}/api/embed/monday${params}`;
}

const App = () => {
  useEffect(() => {
    async function redirectToMaestro() {
      const sessionToken = await monday.get('sessionToken');
      const context = await monday.get('context');
      const maestroUrl = getMaestroUrl(sessionToken, context);
      if (!maestroUrl) {
        return;
      }
      window.location.replace(maestroUrl);
    }
    redirectToMaestro();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <img
        alt="Loading Unito"
        src="https://unito.io/wp-content/themes/unito/assets/build/images/unito-logo.svg?v=2.0.172-#5911"
        width="200"
      />
    </div>
  );
};

export default App;
